import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#ffffff',
        },
        success: {
            main: '#2e7d32',
        },
        error: {
            main: '#d32f2f',
        },
    },
});