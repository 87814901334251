import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    // { field: 'id', headerName: 'Proxy ID', width: 260, type: 'string' },
    { field: 'name', headerName: 'Name', width: 200, type: 'string' },
    { field: 'type', headerName: 'Type', width: 200, type: 'string' },
    { field: 'status', headerName: 'Status', width: 200, type: 'string' },
    { field: 'remote', headerName: 'Remote', width: 260, type: 'string' },
    { field: 'bandwidth_limit', headerName: 'Bandwidth Limit', width: 200, type: 'string' },
];

export default function ProxiesTable(props) {
    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={props.proxies}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onCellDoubleClick={(params) => {
                    if (params.field === 'remote') {
                        window.open(params.value, '_blank');
                    }
                }}
            />
        </div>
    );
}