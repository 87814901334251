import * as React from 'react';
import Chip from '@mui/material/Chip';

export default function Info(props) {
    return (
        <div style={{ padding: '0px 36px' }}>
            <Chip
                label={props.info ? props.info.ready ? "ready" : "not ready" : "unknown"}
                color={props.info ? props.info.ready ? "success" : "error" : "secondary"}
                variant={props.info ? "filled" : "outlined"}
                style={{ width: 80 }}
            />
        </div>
    );
}