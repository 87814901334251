import React, { useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';
import './App.css';
import { environment } from './environment';
import ClientsTable from './components/ClientsTable';
import ProxiesTable from './components/ProxiesTable';
import ButtonAppBar from './components/ButtonAppBar';
import { Box, Button, CircularProgress, Tab, Tabs, Typography } from '@mui/material';


function App() {
  // Auth
  const [keycloakConfig, setKeycloakConfig] = useState(null);
  const [authenticating, setAuthenticating] = useState(true);
  const [subject, setSubject] = useState(null);
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/.well-known/info`).then((response) => {
      if (response.ok) {
        response.json().then((info) => {
          const issuer = info.oidc.issuer;
          const clientId = 'tfarm-web'; // TODO: discover this from the API
          const url = issuer.substring(0, issuer.indexOf('/realms/'));
          const realm = issuer.substring(issuer.indexOf('/realms/') + '/realms/'.length);
          const keycloakConfig = {
            url,
            realm,
            clientId,
          };
          setKeycloakConfig(keycloakConfig);
        }).catch((error) => {
          console.error('error parsing keycloak config', error);
        });
      } else {
        throw new Error('Unable to fetch keycloak config');
      }
    }).catch((error) => {
      console.error('error fetching keycloak config', error);
    });
  }, []);

  useEffect(() => {
    if (!keycloakConfig) {
      return;
    }

    const keycloak = new Keycloak(keycloakConfig);
    keycloak.onAuthSuccess = () => {
      setSubject(keycloak.tokenParsed.sub);
    };
    keycloak.onAuthLogout = () => {
      setSubject(null);
    };
    keycloak.onAuthError = () => {
      setSubject(null);
    };
    keycloak.onTokenExpired = () => {
      console.log('token expired. refreshing...');
      keycloak.updateToken(30).then((valid) => {
        if (valid) {
          setSubject(keycloak.tokenParsed.sub);
        } else {
          console.log('unable to refresh token. logging out...');
          keycloak.logout();
          setSubject(null);
        }
      });
  }

    setKeycloak(keycloak);

    keycloak.init({ onLoad: 'check-sso' }).then((authenticated) => {
      console.log('authenticated', authenticated);
    }).catch((error) => {
      console.error('error initializing auth', error);
    }).finally(() => {
      setAuthenticating(false);
    });
  }, [keycloakConfig]);

  // Tabs
  const TABS = {
    PROXIES: 0,
    CLIENTS: 1,
  };
  const [tabValue, setValue] = React.useState(TABS.PROXIES);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setSelectedClient(null);
  };

  // Data
  const [fetchInfoInterval, setInfoFetchInterval] = useState(null);
  const [fetchDataInterval, setDataFetchInterval] = useState(null);
  
  const [info, setInfo] = useState(null);
  const [clients, setClients] = useState([]);
  const [proxies, setProxies] = useState([]);

  useEffect(() => {
    startFetchInfo();
  }, []);

  useEffect(() => {
    if (subject) {
      startFetchData(keycloak.token);
    }
  }, [subject, keycloak?.token]);

  const startFetchInfo = () => {
    if (fetchInfoInterval) {
      clearInterval(fetchInfoInterval);
    }
    fetchInfo();
    const interval = setInterval(() => {
      // refresh token  
      fetchInfo();
    }, 5000);
    setInfoFetchInterval(interval);
  };

  const startFetchData = (token) => {
    if (fetchDataInterval) {
      clearInterval(fetchDataInterval);
    }
    fetchData(token);
    const interval = setInterval(() => {
      fetchData(token);
    }, 5000);
    setDataFetchInterval(interval);
  };

  const fetchData = (token) => {
    fetchClients(token);
    fetchProxies(token);
  };

  const fetchInfo = () => {
    return fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/.well-known/info`, {
        headers: {}
    }).then((response) => {
        if (response.ok) {
            response.json().then((data) => {
                setInfo(data);
            }).catch((error) => {
                setInfo(null);
                console.error(error);
            });
        } else {
            throw new Error(`Failed to fetch clients: ${response.status} ${response.statusText}`);
        }
    }).catch((error) => {
        setInfo(null);
        console.error(error);
    });
  };

  const fetchClients = (token) => {
    fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/clients`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          const clients = data.map((client) => {
              return {
                  ...client,
                  id: client.client_id,
                  created_at: new Date(client.created_at),
                  last_used_at: client.last_used_at ? new Date(client.last_used_at) : null,
              };
          });
          setClients(clients);
        }).catch((error) => {
          // TODO: handle these
          console.error(error);
        });
      } else {
        throw new Error(`Failed to fetch clients: ${response.status} ${response.statusText}`);
      }
    }).catch((error) => {
      // TODO: handle these
      console.error(error);
    });
  }

  const fetchProxies = (token) => {
    fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/proxies`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          const proxies = data.map((proxy) => {
              return {
                ...proxy,
                id: proxy.name
              };
          }).sort((a, b) => a.name.localeCompare(b.name));
          setProxies(proxies);
        }).catch((error) => {
          // TODO: handle these
          console.error(error);
        });
      } else {
        throw new Error(`Failed to fetch proxies: ${response.status} ${response.statusText}`);
      }
    }).catch((error) => {
      // TODO: handle these
      console.error(error);
    });
  }

  // Clients
  const [selectedClient, setSelectedClient] = useState(null);

  const onSelectedClientChange = (clientId) => {
    setSelectedClient(clientId);
  };

  const onClientCreate = () => {
    fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/clients`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${keycloak.token}`
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          const client = {
            ...data,
            id: data.client_id,
            created_at: new Date(data.created_at),
            last_used_at: data.last_used_at ? new Date(data.last_used_at) : null,
          };
          setClients([...clients, client]);
          // setSelectedClient(client.id);
        }).catch((error) => {
          console.error('failed to create client', error);
        });
      } else {
        throw new Error(`Failed to create client: ${response.status} ${response.statusText}`);
      }
    }).catch((error) => {
      console.error('failed to create client', error);
    });
  };

  const onClientCredentials = (clientId) => {
    const client = clients.find((client) => client.id === clientId);
    if (client) {
      fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/clients/${client.id}/credentials.json`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${keycloak.token}`
        }
      }).then((response) => {
        if (response.ok) {
          // download the file as credentials.json
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `credentials.json`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }).catch((error) => {
            console.error('failed to download client credentials', error);
          });
        } else {
          throw new Error(`Failed to download client credentials: ${response.status} ${response.statusText}`);
        }
      }).catch((error) => {
        console.error('failed to download client credentials', error);
      });
    }
  };

  const onClientDelete = (clientId) => {
    const client = clients.find((client) => client.id === clientId);
    if (client) {
      const confirmed = window.confirm(`Are you sure you want to delete client ${client.id}?`);
      if (confirmed) {
        fetch(`${environment.REACT_APP_RANCH_API_ENDPOINT}/api/clients/${client.id}`, {
          method: 'DELETE',
          headers: {
            "Authorization": `Bearer ${keycloak.token}`
          }
        }).then((response) => {
          if (response.ok) {
            setClients(clients.filter((client) => client.id !== clientId));
          } else {
            throw new Error(`Failed to delete client: ${response.status} ${response.statusText}`);
          }
        }).catch((error) => {
          console.error('failed to delete client', error);
        });
      }
    }
  };

  return (
    <div className='App'>
      <div className='App-header'>
        <ButtonAppBar
          title='Tunnel Farm'
          info={info}
          loginTitle={subject ? 'Logout' : 'Login'}
          onLoginClick={() => subject ? keycloak.logout() : keycloak.login()}
        ></ButtonAppBar>
      </div>
      <div className='App-content'>
        {authenticating &&
          <div className='authenticating'>
            <CircularProgress />
          </div>
        }
        {!authenticating &&
          <div className='w-full'>
            {subject &&
              <div>
                <div className='flex justify-between'>
                  <Box>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                      <Tab label="Proxies" />
                      <Tab label="Clients" />
                    </Tabs>
                  </Box>
                  <Box className='flex justify-center items-center'>
                    { tabValue === TABS.CLIENTS &&
                    <div >
                      <Button
                        style={{ marginRight: '12px' }}
                        size='small'
                        color='primary'
                        onClick={() => {
                          onClientCreate();
                        }}
                      >
                        Create
                      </Button>
                      <Button
                        style={{ marginRight: '12px' }}
                        size='small'
                        color='primary'
                        disabled={!selectedClient}
                        onClick={() => {
                          onClientCredentials(selectedClient);
                        }}
                      >
                        Download
                      </Button>
                      <Button
                        style={{ marginRight: '12px' }}
                        size='small'
                        color='error'
                        disabled={!selectedClient}
                        onClick={() => {
                          onClientDelete(selectedClient);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                    }
                    { tabValue === TABS.PROXIES &&
                      // TODO: delete proxy
                      <></>
                    }
                  </Box>
                </div>
    
                {tabValue === TABS.PROXIES &&
                  <Box>
                    <ProxiesTable proxies={proxies}></ProxiesTable>
                  </Box>
                }
                {tabValue === TABS.CLIENTS &&
                  <Box>
                    <ClientsTable
                      clients={clients}
                      onSelectedClientChange={onSelectedClientChange}
                    ></ClientsTable>
                  </Box>
                }
              </div>
            }
            {!subject &&
              <div className='not-logged-in'>
                <Typography variant='h5' component='h2'>
                  Welcome to Tunnel Farm ranch!
                </Typography>
                <Typography variant='body1' component='p'>
                  Please login to continue.
                </Typography>
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default App;
