import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'client_id', headerName: 'Client ID', width: 260, type: 'string' },
    { field: 'created_at', headerName: 'Created', width: 200, type: 'dateTime' },
    { field: 'last_used_at', headerName: 'Last Used', width: 200, type: 'dateTime' },
    //   {
    //     field: 'age',
    //     headerName: 'Age',
    //     type: 'number',
    //     width: 90,
    //   },
    //   {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    //   },
];

export default function ClientsTable(props) {
    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={props.clients}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onRowSelectionModelChange={(rowIds) => {
                    if (rowIds.length === 1) {
                        props.onSelectedClientChange(rowIds[0]);
                    } else {
                        props.onSelectedClientChange(null);
                    }
                }}
            />
        </div>
    );
}